import request from "@/apis/http";
import requestEco from "@/apis/httpEco";
import { AxiosPromise } from "axios";
import {
  Member,
  MemberTeam,
  ContactUsParams,
  MemberRole,
  MembersParams
} from "@/typings/member";

export function getMembers(data: MembersParams): AxiosPromise<Member[]> {
  return request({
    url: "/members/",
    method: "GET",
    params: data
  });
}

export function getMember(id?: number): AxiosPromise<Member> {
  if (id) {
    return request({
      url: `/member/${id}/`,
      method: "GET"
    });
  }
  return request({
    url: `/member/`,
    method: "GET"
  });
}

export function getMemberRoles(
  id?: number,
  params?: { category?: string }
): AxiosPromise<MemberRole[]> {
  if (id) {
    return request({
      url: `/member/${id}/roles/`,
      method: "GET",
      params
    });
  }
  return request({
    url: `/member/roles/`,
    method: "GET",
    params
  });
}

// Haven't id means get self teams data by token.
export function getMemberTeams(
  id?: number,
  params?: { category?: string }
): AxiosPromise<MemberTeam[]> {
  if (id) {
    return request({
      url: `/member/${id}/teams/`,
      method: "GET",
      params
    });
  }
  return request({
    url: "/member/teams/",
    method: "GET",
    params
  });
}

export function getMemberBusinessUnits(id?: number): AxiosPromise<string[]> {
  if (id) {
    return request({
      url: `/member/${id}/business_units/`,
      method: "GET"
    });
  }
  return request({
    url: "/member/business_units/",
    method: "GET"
  });
}

export function postMember(params: Member, id?: number) {
  const data = {
    email: params.email,
    nickname: params.nickname,
    role: params.role,
    teams: params.teams,
    phone: params.phone,
    active: params.active,
    business_unit: params.business_unit
  };
  if (id) {
    return request({
      url: `/member/${id}/`,
      method: "PUT",
      data
    });
  }
  return request({
    url: `/member/`,
    method: "PUT",
    data
  });
}

export function postMemberPassword(data: { password: string }) {
  return request({
    url: `/member/`,
    method: "PUT",
    data
  });
}

export function postMemberAvatar(file: File, id?: number) {
  const data = new FormData();
  data.append("file", file);
  if (id) data.append("member", JSON.stringify(id));

  return request({
    url: "/member/medias/",
    method: "POST",
    data
  });
}

export function postContactUs(data: ContactUsParams) {
  return request({
    url: `/mail/contact_us/`,
    method: "POST",
    data
  });
}

export function getEcoUserFavorites(): AxiosPromise {
  return requestEco({
    url: `/followed-fabric-variants`,
    method: "GET"
  });
}
